class JockeyApiPolicies {
	constructor() {
		this.envManager = new EnviromentManager();
		this.url = this.envManager.getUrlApi() + 'organization/configuration/';
	}

	async show(page) {
		const direction = this.url + `show?page=${page}`;
		return await fetch(direction);
	}

	async getPrivacyPolicy() {
		const response = await this.show('privacy_policies');
		return response.json();
	}

	async getPrivacyPolicySocialNetwork() {
		const response = await this.show('social_network_privacy_policies');
		return response.json();
	}
}

class EnviromentManager {
	constructor() {
		this.URL_FRONT_QAJP = 'https://website.qa-jp.jockeyplaza.com.pe/';
		this.URL_API_QAJP = 'https://jp-api-qa.qa-jp.jockeyplaza.com.pe/api/';
		this.URL_FRONT_PROD = 'https://jockeyplaza.com.pe/';
		this.URL_API_PROD = 'https://api-web.jockeyplaza.com.pe/api/';
		this.href = window.location.href;
	}

	getUrlApi() {
		// Si estas corriendo en local o qa-jp es la misma api
		if (this.isProd()) {
			return this.URL_API_PROD;
		}
		return this.URL_API_QAJP;
	}

	isProd() {
		return this.href.includes(this.URL_FRONT_PROD);
	}
}
